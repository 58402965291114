import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}
const FeedManagement = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M336.3,512h-23.5c-19,0-34.5-15.5-34.5-34.5v-8c-8.1-2.6-16-5.8-23.5-9.8l-5.6,5.6c-13.7,13.7-35.6,13.3-48.8,0l-16.6-16.6
		c-13.3-13.3-13.7-35.2,0-48.8l5.6-5.6c-3.9-7.6-7.2-15.4-9.8-23.5h-8c-19,0-34.5-15.5-34.5-34.5v-23.5c0-19,15.5-34.5,34.5-34.5h8
		c2.6-8.1,5.8-16,9.8-23.5l-5.6-5.6c-13.7-13.7-13.3-35.5,0-48.8l16.6-16.6c13.3-13.3,35.2-13.7,48.8,0l5.6,5.6
		c7.6-3.9,15.4-7.2,23.5-9.8v-8c0-19,15.5-34.5,34.5-34.5h23.5c19,0,34.5,15.5,34.5,34.5v8c8.1,2.6,16,5.8,23.5,9.8l5.6-5.6
		c13.7-13.7,35.6-13.3,48.8,0l16.6,16.6c13.3,13.3,13.7,35.2,0,48.8l-5.6,5.6c3.9,7.6,7.2,15.4,9.8,23.5h8c19,0,34.5,15.5,34.5,34.5
		v23.5c0,19-15.5,34.5-34.5,34.5h-8c-2.6,8.1-5.8,16-9.8,23.5l5.6,5.6c13.7,13.7,13.3,35.5,0,48.8l-16.6,16.6
		c-13.3,13.3-35.2,13.7-48.8,0l-5.6-5.6c-7.6,3.9-15.4,7.2-23.5,9.8v8C370.8,496.5,355.3,512,336.3,512z M258.4,436.7
		c10.5,6.2,21.8,10.9,33.6,13.9c4.9,1.3,8.2,5.6,8.2,10.6v16.2c0,6.9,5.6,12.5,12.5,12.5h23.5c6.9,0,12.5-5.6,12.5-12.5v-16.2
		c0-5,3.4-9.4,8.2-10.6c11.8-3,23.1-7.7,33.6-13.9c4.3-2.6,9.8-1.9,13.4,1.7l11.5,11.5c5,5,12.9,4.8,17.7,0l16.6-16.6
		c4.8-4.8,5-12.8,0-17.7L438.4,404c-3.5-3.5-4.2-9-1.7-13.4c6.2-10.5,10.9-21.8,13.9-33.6c1.3-4.9,5.6-8.2,10.6-8.2h16.2
		c6.9,0,12.5-5.6,12.5-12.5v-23.5c0-6.9-5.6-12.5-12.5-12.5h-16.2c-5,0-9.4-3.4-10.6-8.2c-3-11.8-7.7-23.1-13.9-33.6
		c-2.6-4.3-1.9-9.8,1.7-13.4l11.5-11.5c5-5,4.8-12.9,0-17.7l-16.6-16.6c-4.8-4.8-12.8-5-17.7,0L404,210.6c-3.5,3.5-9,4.2-13.4,1.7
		c-10.5-6.2-21.8-10.9-33.6-13.9c-4.9-1.3-8.2-5.6-8.2-10.6v-16.2c0-6.9-5.6-12.5-12.5-12.5h-23.5c-6.9,0-12.5,5.6-12.5,12.5v16.2
		c0,5-3.4,9.4-8.2,10.6c-11.8,3-23.1,7.7-33.6,13.9c-4.3,2.6-9.8,1.9-13.4-1.7l-11.5-11.5c-5-5-12.9-4.8-17.7,0l-16.6,16.6
		c-4.8,4.8-5,12.8,0,17.7l11.5,11.5c3.5,3.5,4.2,9,1.7,13.4c-6.2,10.5-10.9,21.8-13.9,33.6c-1.3,4.9-5.6,8.2-10.6,8.2h-16.2
		c-6.9,0-12.5,5.6-12.5,12.5v23.5c0,6.9,5.6,12.5,12.5,12.5h16.2c5,0,9.4,3.4,10.6,8.2c3,11.8,7.7,23.1,13.9,33.6
		c2.6,4.3,1.9,9.8-1.7,13.4l-11.5,11.5c-5,5-4.8,12.9,0,17.7l16.6,16.6c4.8,4.8,12.8,5,17.7,0l11.5-11.5
		C247.6,435.8,253.1,433.5,258.4,436.7L258.4,436.7z"
        />
        <path
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M324.5,406.1c-45,0-81.6-36.6-81.6-81.6s36.6-81.6,81.6-81.6s81.6,36.6,81.6,81.6S369.5,406.1,324.5,406.1z M324.5,264.9
		c-32.9,0-59.6,26.7-59.6,59.6s26.7,59.6,59.6,59.6s59.6-26.7,59.6-59.6S357.4,264.9,324.5,264.9L324.5,264.9z"
        />

        <path
          stroke={color}
          strokeWidth="15px"
          opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M183.6,448.8c-7.5-7.5-10.9-17.7-10-27.7c-1.8,0.3-3.7,0.7-5.5,0.9c2.1-14.9,7.1-29.1,14.7-41.9c-1.2-3.1-2.3-6.2-3.3-9.3
		h-8c-19,0-34.5-15.5-34.5-34.5v-23.5c0-19,15.5-34.5,34.5-34.5h8c2.6-8.1,5.8-16,9.8-23.5l-5.6-5.6c-5.9-5.9-9.1-13.2-9.9-20.8
		c-1.8,0.4-3.7,0.7-5.6,0.9c2.1-15.2,7.3-29.6,15.1-42.6c0.1-0.2,0.2-0.4,0.4-0.6c4.7-7.7,10.3-14.8,16.8-21.3
		c17.7-17.7,40.2-28.9,64.5-32.3c-2.5,17.5-8.9,34-18.9,48.5c1.1,0.8,2.1,1.7,3.1,2.7l5.6,5.6c7.6-3.9,15.4-7.2,23.5-9.8v-8
		c0-12,6.1-22.5,15.4-28.7c1.6-8.6,2.4-17.4,2.4-26.3c0-2-0.3-4.1-1.1-6c-2.3-5.6-7.8-9.3-13.9-9.3c-27.6,0-54,7.7-76.8,22.1
		c0.4-4.2,0.6-8.4,0.6-12.7c0-38.5-15-74.7-42.2-101.9c-5.9-5.9-15.4-5.9-21.2,0c-31.3,31.3-45.2,73.6-41.6,114.6
		C77,109,50.6,101.2,22.9,101.2c-4,0-7.8,1.6-10.6,4.4c-2.8,2.8-4.4,6.6-4.4,10.6c0,29.6,8.9,57.9,25.4,81.8
		c-3.4-0.2-6.9-0.4-10.4-0.4c-4,0-7.8,1.6-10.6,4.4c-2.8,2.8-4.4,6.6-4.4,10.6c0,30.3,9.4,58.5,25.5,81.8c-3.5-0.2-7-0.4-10.5-0.4
		c-8.3,0-15,6.7-15,15c0,74.4,56.6,135.8,129.1,143.3l0,38.7c0,8.3,6.7,15,15,15c4.1,0,7.9-1.7,10.6-4.4c2.7-2.7,4.4-6.5,4.4-10.6
		v-38.7c5.9-0.6,11.6-1.6,17.3-2.9L183.6,448.8z M103.6,164.7c17.7,17.7,28.9,40.2,32.3,64.5c-24.3-3.4-46.8-14.6-64.5-32.3
		c-17.7-17.7-28.9-40.2-32.3-64.5C63.4,135.8,85.9,147,103.6,164.7z M135.9,325.6c-50-7.1-89.7-46.8-96.8-96.8
		C89.1,235.9,128.7,275.6,135.9,325.6z M71.3,389.7c-17.7-17.7-28.9-40.2-32.3-64.5c24.4,3.4,46.8,14.6,64.5,32.3
		c17.7,17.7,28.9,40.2,32.3,64.5C111.5,418.6,89,407.4,71.3,389.7z M152,42.2c14.8,19.6,22.8,43.4,22.8,68.5
		c0,21.8-6.1,42.7-17.4,60.7c0,0,0,0,0,0.1c-1.7,2.6-3.4,5.2-5.3,7.7C121.7,138.7,121.7,82.6,152,42.2z"
        />
      </svg>
    </SvgIcon>
  );
};

export default FeedManagement;
