import RouteSelectWarning from "Components/RouteSelectWarning";
import { Route, Routes } from "react-router-dom";
import Task from "./Task";
import Tasks from "./Tasks";

type Props = {};

const TasksRoutes = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Tasks />}>
        <Route
          index
          element={<RouteSelectWarning warning={"Select a Task"} />}
        />
        <Route path="/:id" element={<Task />} />
        <Route
          path="*"
          element={<RouteSelectWarning warning={"No Tasks Selected"} />}
        />
      </Route>
    </Routes>
  );
};

export default TasksRoutes;
