import React from "react";
import logoDark from "./dark/logo.png";
import logoDarkText from "./dark/logo-text.png";
import logoDarkTextStrapline from "./dark/logo-text-strapline.png";
import logoLight from "./light/logo.png";
import logoLightText from "./light/logo-text.png";
import logoLightTextStrapline from "./light/logo-text-strapline.png";
import { Box } from "@mui/material";
import { BoxProps } from "@mui/system";

interface Props extends BoxProps {
  reverse?: boolean;
  text?: boolean;
  strapline?: boolean;
  image?: boolean;
}
const Logo = (props: Props) => {
  const logo = React.useMemo(() => {
    let _logo = logoDark;
    if (props.reverse) {
      if (props.text && props.strapline) _logo = logoLightTextStrapline;
      else if (props.text) _logo = logoLightText;
      else _logo = logoLight;
    } else {
      if (props.text && props.strapline) _logo = logoDarkTextStrapline;
      else if (props.text) _logo = logoDarkText;
      else _logo = logoDark;
    }
    return _logo;
  }, [props.reverse, props.strapline, props.text]);

  return props.image ? (
    <img src={logo} style={{ width: "100%" }} alt="Feedlync" />
  ) : (
    <Box
      style={{
        backgroundImage: `url(${logo})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      {...props}
    />
  );
};

export default Logo;
