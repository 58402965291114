import { Box, Card, useTheme } from "@mui/material";
import React from "react";

type Props = {};

const Toolbar = (props: React.PropsWithChildren<Props>) => {
  const theme = useTheme();

  return (
    <>
      <Card elevation={4} sx={{ bgcolor: theme.palette.back.main }}>
        <Box p={1} display="flex" justifyContent="space-between" alignItems={"center"} data-cy={"page-toolbar"}>
          {props.children}
        </Box>
      </Card>
    </>
  );
};

export default Toolbar;
