import { IconButton, IconButtonProps, useTheme } from "@mui/material";
import React from "react";

type ToggleIconButtonProps = IconButtonProps & {
  active?: boolean;
};

export const ToggleIconButton = (
  props: React.PropsWithChildren<ToggleIconButtonProps>
) => {
  const theme = useTheme();
  const { active, ...iconButtonProps } = props;

  return (
    <IconButton
      sx={{
        transition: "300ms all",
        color: props.active ? "white" : theme.palette.grey[300],
        backgroundColor: props.active
          ? theme.palette.primary.main
          : "transparent",
        "&:hover": {
          color: props.active ? "white" : theme.palette.grey[400],
          backgroundColor: props.active
            ? theme.palette.primary.main
            : theme.palette.grey[100],
        },
      }}
      size="small"
      {...iconButtonProps}
    />
  );
};
