import {
  hoursToMilliseconds,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from "date-fns";
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import { ETab } from "types/generics";

export const compareSemVer = (a: string, b: string) => {
  var pa = a.split(".");
  var pb = b.split(".");
  for (var i = 0; i < 3; i++) {
    var na = Number(pa[i]);
    var nb = Number(pb[i]);
    if (na > nb) return 1;
    if (nb > na) return -1;
    if (!isNaN(na) && isNaN(nb)) return 1;
    if (isNaN(na) && !isNaN(nb)) return -1;
  }
  return 0;
};

export const time = (obj: {
  hours?: number;
  minutes?: number;
  seconds?: number;
}) => {
  return (
    hoursToMilliseconds(obj.hours ?? 0) +
    minutesToMilliseconds(obj.minutes ?? 0) +
    secondsToMilliseconds(obj.minutes ?? 0)
  );
};

export const round = (decimalPlaces: number) => (num: number) =>
  Math.round(num * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);

export const customDateFormat = (
  value: Date | string | undefined,
  time?: boolean
): string => {
  if (!value) return "";
  const _value = typeof value === "string" ? new Date(value) : value;
  if (_value instanceof Date && isValid(_value)) {
    const _format = time === false ? "yyyy-MM-dd" : "yyyy-MM-dd HH:mm";
    return format(_value, _format);
  } else if (_value instanceof Date) {
    _value.toLocaleTimeString();
  } else {
    return _value;
  }
  return "";
};

export const clamp = (min?: number, max?: number) => (num: number) => {
  if (min == null || max == null) return num;
  return Math.min(Math.max(num, min), max);
};
export const clampGreaterThanZero = clamp(0, Infinity);
export const clampLessThanZero = clamp(-Infinity, 0);

export const filterHiddenTabs = (tabs: ETab[], hiddenTabs: Partial<ETab>[]) => {
  return tabs.filter((t) => {
    const path = hiddenTabs.find((ht) => ht.path === t.path);
    if (!path) return true;
    if (path.hidden) return false;
    return true;
  });
};

export const isObjectDifferent = <T extends object>(
  objA: T,
  objB: T
): boolean => {
  for (let key in objA) {
    if (objA[key] !== objB[key]) return true;
  }
  return false;
};
