import { Save as SaveIcon } from "@mui/icons-material";
import { Button, ButtonProps, CircularProgress, IconButton, IconButtonProps } from "@mui/material";
import useLanguage from "hooks/useLanguage";
import { memo } from "react";

type Props = { saving?: boolean; icon?: boolean } & ButtonProps; //& IconButtonProps;

const Save = (props: Props) => {
  return props.icon ? <IconButtonSave {...props} /> : <ButtonSave {...props} />;
};

export default memo(Save);

interface ButtonSaveProps extends ButtonProps {
  saving?: boolean;
  icon?: boolean;
  title?: string;
}

const ButtonSave = (props: ButtonSaveProps) => {
  const { t } = useLanguage();
  const { title = t("Save"), saving, endIcon, ...rest } = props;

  return (
    <Button
      data-cy="save-button"
      variant="contained"
      endIcon={props.saving ? <CircularProgress size={18} color="inherit" /> : <SaveIcon />}
      {...rest}
      disabled={!!props.saving || !!props.disabled}
    >
      {title}
    </Button>
  );
};

interface IconButtonSaveProps extends IconButtonProps {
  saving?: boolean;
  icon?: boolean;
}

const IconButtonSave = (props: IconButtonSaveProps) => {
  const { saving, icon, ...rest } = props;

  return (
    <IconButton
      data-cy="save-icon-button"
      sx={{ color: "primary.dark" }}
      {...rest}
      disabled={!!props.saving || !!props.disabled}
    >
      {props.saving ? <CircularProgress size={18} color="inherit" /> : <SaveIcon color="inherit" />}
    </IconButton>
  );
};
