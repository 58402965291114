import { CloseOutlined } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import useLanguage from "hooks/useLanguage";

const Close = (props: ButtonProps) => {
  const { t } = useLanguage();

  return (
    <Button
      variant="contained"
      disabled={props.disabled}
      endIcon={<CloseOutlined />}
      data-cy="new-button"
      {...props}
    >
      {t("Close")}
    </Button>
  );
};

export default Close;
