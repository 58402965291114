import React from "react";

type Props = {
  children: React.ReactNode;
};

type State = {
  hasError: boolean;
  errorMessage: string;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true, errorMessage: error.message };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI

      return (
        <div>
          <h1>Something went wrong.</h1>

          <p>{this.state.errorMessage}</p>
        </div>
      );
    }

    return this.props.children;
  }
}
