import React, { useEffect } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { useLogout } from "hooks/useLogout";

interface Props {}

const Logout = (props: Props) => {
  const logout = useLogout();
  React.useEffect(() => {
    logout();
  }, []);
  return <>Logging out...</>;
};

export default Logout;
