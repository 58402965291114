import { createTheme, ThemeOptions, ThemeProvider } from "@mui/material";
import useLocalStorage from "hooks/useLocalStorage";
import React from "react";

const GREEN_LIGHT = "#e8f4b6";
const GREEN = "rgb(187,211,83)";
const GREEN_DARK = "rgb(159, 180, 69)";

const ORANGE = "#FDB827";

const GREY_LIGHT = "rgb(229, 233, 234)";
const GREY_MID = "rgb(244, 246, 247)";
const GREY_DARK = "rgb(3, 47, 60)";
const GREY_BLUE_DARK = "rgb(2, 30, 38)";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: React.CSSProperties["color"];
    };
  }

  interface Palette {
    back: Palette["primary"];
  }
  interface PaletteOptions {
    back: PaletteOptions["primary"];
  }
  interface ThemeOptions {
    status?: {
      danger: React.CSSProperties["color"];
    };
  }
}

const components: ThemeOptions["components"] = {
  MuiPaper: {
    defaultProps: {
      // backgroundColor: "#fff",
    },
  },
  MuiButton: {
    defaultProps: {
      size: "small",
      color: "primary",
    },
    styleOverrides: {
      root: {
        fontSize: "0.7rem",
      },

      endIcon: {
        size: "small",
      },
    },
  },
  MuiButtonGroup: {
    defaultProps: {
      size: "small",
    },
  },
  MuiCheckbox: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFab: {
    defaultProps: {
      size: "small",
    },
  },
  MuiFormControl: {
    defaultProps: {
      size: "small",
      margin: "dense",
    },
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiFormHelperText: {
    defaultProps: {
      margin: "dense",
    },
  },
  MuiIconButton: {
    defaultProps: {
      size: "small",
    },
  },
  MuiInputBase: {
    defaultProps: {
      margin: "dense",
    },
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiInputLabel: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
    styleOverrides: {
      root: {
        fontSize: "0.8rem",
        lineHeight: "2rem",
      },
    },
  },
  MuiRadio: {
    defaultProps: {
      size: "small",
    },
  },
  MuiSwitch: {
    defaultProps: {
      size: "small",
    },
  },
  MuiTextField: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
    styleOverrides: {
      root: {
        fontSize: "1rem",
      },
    },
  },
  MuiSelect: {
    defaultProps: {
      margin: "dense",
      size: "small",
    },
  },
  MuiList: {
    defaultProps: {
      dense: true,
    },
  },
  MuiMenuItem: {
    defaultProps: {
      dense: true,
    },
  },
  MuiTable: {
    defaultProps: {
      size: "small",
    },
  },
};

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: GREEN,
      dark: GREEN_DARK,
      light: GREEN_LIGHT,
    },
    secondary: {
      main: ORANGE,
    },
    text: {
      primary: "#232a04e7",
    },
    back: {
      light: GREY_LIGHT,
      main: GREY_MID,
      dark: GREY_DARK,
    },
    background: { default: GREY_LIGHT },
  },
  components,
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: GREEN,
    },
    secondary: {
      main: ORANGE,
    },
    text: {
      primary: "#fff",
    },
    back: {
      light: GREY_LIGHT,
      main: GREY_DARK,
      dark: GREY_BLUE_DARK,
    },
    background: { default: GREY_MID, paper: GREY_DARK },
  },
  components,
});
export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

export function Theme({ children }: { children: React.ReactNode }) {
  const [mode, setMode] = useLocalStorage<"light" | "dark">("ColorMode", "light");

  const colorMode = {
    toggleColorMode: () => {
      setMode((prevMode) => {
        return prevMode === "light" ? "dark" : "light";
      });
    },
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={mode === "dark" ? darkTheme : lightTheme}>{children}</ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export const useColorMode = () => React.useContext(ColorModeContext);
