import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Loads = ({ color = "#7e7e7e", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 730">
        <circle
          fill="none"
          stroke={color}
          strokeWidth="60px"
          // opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          cx="646"
          cy="584"
          r="121"
        />
        <polyline
          fill="none"
          stroke={color}
          strokeWidth="60px"
          // opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          points="25 646 87 646 147 595 466 595"
        />
        <polyline
          fill="none"
          stroke={color}
          strokeWidth="60px"
          // opacity={opacity}
          strokeLinecap="round"
          strokeLinejoin="round"
          points="828 595 887 595 975 63 104 25 221 526"
        />
      </svg>
    </SvgIcon>
  );
};

export default Loads;
