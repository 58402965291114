import { Clear, FilterList, Refresh } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Card,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import useLanguage from "hooks/useLanguage";
import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { GUID } from "types/generics";
import { UseQueryResult } from "react-query";
import useDimensions from "hooks/useDimensions";

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export interface ItemListProps<T> {
  query: UseQueryResult<T[], Error>;
  label?: KeysMatching<T, string | undefined | null>;
  annotation?: string;
  showAnnotation?: (i: T) => boolean;
}
export type TPossibleKeys = {
  name?: string | null;
  displayName?: string | null; // THIS SHOULD BE IMPLEMENTED AS AN ALTERNATIVE TO q.name - q[props.displayName]
  id?: GUID | null;
};

function ItemList<T extends TPossibleKeys>(props: ItemListProps<T>) {
  const { t } = useLanguage();
  let { id = "" } = useParams();
  const [filter, setFilter] = React.useState("");
  const theme = useTheme();
  const [divRef, dimensions] = useDimensions();

  let linkStyle: React.CSSProperties = {
    textDecoration: "none",
    color: "inherit",
    transition: "background-color 0.25s",
  };
  let activeStyle: React.CSSProperties = {
    ...linkStyle,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
  };

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
    setFilter(e.currentTarget.value);

  const _data = React.useMemo(() => {
    return (
      props.query.data
        ?.map((d) =>
          props.label && d[props.label] ? { ...d, name: d[props.label] } : d
        )
        ?.filter(
          (d) => !d.name || d.name?.toLowerCase().includes(filter.toLowerCase())
        )
        .sort((a, b) =>
          !a.name || !b.name
            ? 0
            : a.name < b.name
            ? -1
            : a.name > b.name
            ? 1
            : 0
        ) || []
    );
  }, [filter, props.query.data]);

  return (
    <div
      ref={divRef}
      style={{ position: "sticky", top: 0 }}
      data-cy={"page-side-menu"}
    >
      <Card
        variant="outlined"
        sx={{
          bgcolor: "transparent",
          width: 240,
          minWidth: 200,
          marginRight: 2,
          padding: 1,
          height: `calc(100vh - ${dimensions.top}px - 16px)`,
        }}
      >
        <Box display="flex" height="100%" flexDirection="column">
          <Box
            flexShrink={1}
            display="flex"
            flexDirection={"row"}
            alignItems="center"
          >
            <Box flexGrow={1} mr={1}>
              <TextField
                value={filter}
                fullWidth
                onChange={handleFilter}
                label={t("Filter")}
                data-cy={"page-item-list-filter"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {!filter ? (
                        <FilterList />
                      ) : (
                        <IconButton onClick={() => setFilter && setFilter("")}>
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <IconButton onClick={() => props.query.refetch()} size="small">
                <Refresh />
              </IconButton>
            </Box>
          </Box>
          <Box flexGrow={1} style={{ overflowY: "auto", overflowX: "hidden" }}>
            {props.query.isLoading ? <LinearProgress /> : null}
            <List component="nav" data-cy={"page-item-list"}>
              {_data.map((item) =>
                !!item.id ? (
                  <NavLink
                    to={item.id}
                    key={item.id}
                    style={({ isActive }) =>
                      isActive ? activeStyle : linkStyle
                    }
                  >
                    <ListItem
                      selected={id === item.id}
                      style={{ backgroundColor: "inherit" }}
                    >
                      <ListItemText
                        style={{ whiteSpace: "nowrap" }}
                        primary={item.name ?? t("Unnamed")}
                      />
                      {props.showAnnotation?.(item) ? (
                        <Avatar
                          sx={{
                            width: 20,
                            height: 20,
                            bgcolor: "secondary.main",
                          }}
                        >
                          <Typography variant="button">
                            {props.annotation}
                          </Typography>
                        </Avatar>
                      ) : null}
                    </ListItem>
                  </NavLink>
                ) : null
              )}
            </List>
          </Box>
        </Box>
      </Card>
    </div>
  );
}

export default ItemList;
