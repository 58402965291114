import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const Costings = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <rect x="256" y="378.3" width="192.5" height="40" />
        <rect x="277.3" y="321.3" width="192.5" height="40" />
        <rect x="298.6" y="264.4" width="192.5" height="40" />
        <rect x="40" y="378.3" width="192.5" height="40" />
        <rect x="47.7" y="321.3" width="192.5" height="40" />
        <rect x="40" y="264.4" width="192.5" height="40" />
        <rect x="47.7" y="207.4" width="192.5" height="40" />
        <rect x="32.8" y="150.5" width="192.5" height="40" />
        <rect x="57.5" y="93.5" width="192.5" height="40" />
        <rect
          x="339.2"
          y="78.3"
          transform="matrix(0.5771 -0.8167 0.8167 0.5771 9.3593 367.2192)"
          width="40"
          height="192.5"
        />
      </svg>
    </SvgIcon>
  );
};

export default Costings;
