import { Backdrop, CircularProgress } from "@mui/material";
import TasksRoutes from "Features/Tasks/Routes";
import { AppLayout } from "Layout/AppLayout";
import Logout from "Providers/Auth/Logout";
import NotFound from "Pages/NotFound";
import React from "react";
import { Route, Routes as BrowserRoutes } from "react-router-dom";

const NIR = React.lazy(() => import("./NIR"));
const FeedManagement = React.lazy(() => import("./FeedManagement"));
const LoadsPrecision = React.lazy(() => import("./LoadsPrecision"));
const Analysis = React.lazy(() => import("./Analysis"));
const StockManagement = React.lazy(() => import("./StockManagement"));
const Settings = React.lazy(() => import("./Settings"));
const ErrorPage = React.lazy(() => import("Pages/ErrorPage"));
const NoFarms = React.lazy(() => import("Pages/NoFarms"));
const Dashboard = React.lazy(() => import("Features/Dashboard"));
const Costings = React.lazy(() => import("Features/Costings"));

function Routes() {
  return (
    <BrowserRoutes>
      <Route path="*" element={<NotFound />} />
      <Route path="/" element={<AppLayout />}>
        <Route index element={<Dashboard />} />
        <Route path="logout" element={<Logout />} />
        <Route path="nir/*" element={<Suspense component={<NIR />} />} />
        <Route
          path="feed-management/*"
          element={<Suspense component={<FeedManagement />} />}
        />
        <Route
          path="loads-precision/*"
          element={<Suspense component={<LoadsPrecision />} />}
        />
        <Route
          path="analysis/*"
          element={<Suspense component={<Analysis />} />}
        />
        <Route
          path="stock-management/*"
          element={<Suspense component={<StockManagement />} />}
        />
        <Route
          path="tasks/*"
          element={<Suspense component={<TasksRoutes />} />}
        />
        <Route
          path="costings/*"
          element={<Suspense component={<Costings />} />}
        />
        <Route
          path="settings/*"
          element={<Suspense component={<Settings />} />}
        />
        <Route
          path="costings/*"
          element={<Suspense component={<Costings />} />}
        />
      </Route>
      <Route path="no-farms" element={<Suspense component={<NoFarms />} />} />
      <Route path="error" element={<Suspense component={<ErrorPage />} />} />
    </BrowserRoutes>
  );
}

export default Routes;

interface SuspenseProps {
  component: React.ReactElement;
}
export const Suspense = (props: SuspenseProps) => {
  return (
    <React.Suspense
      fallback={
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      }
    >
      {props.component}
    </React.Suspense>
  );
};
