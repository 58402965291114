import { BoxProps } from "@mui/material";
import OutlinedCard from "Components/OutlinedCard";
import useIsModuleActive from "hooks/useIsModuleActive";
import React from "react";
import { EModules } from "types/modules";

type Props = {
  module: EModules;
  wrap?: boolean;
} & BoxProps;

const IfModuleActive = (props: React.PropsWithChildren<Props>) => {
  const isModuleActive = useIsModuleActive();
  const { wrap, module, ...boxProps } = props;
  return (
    <>
      {isModuleActive(props.module) ? (
        props.wrap ? (
          <OutlinedCard {...boxProps}>{props.children}</OutlinedCard>
        ) : (
          <>{props.children}</>
        )
      ) : null}
    </>
  );
};

export default IfModuleActive;
