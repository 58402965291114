import { DeleteForever } from "@mui/icons-material";
import { Button, ButtonProps, CircularProgress, IconButton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import ConfirmDelete from "Components/ConfirmDelete";
import useLanguage from "hooks/useLanguage";
import React from "react";
import { UseMutationResult } from "react-query";
import { useNavigate } from "react-router-dom";
import { GUID } from "types/generics";

interface Props<T> extends ButtonProps {
  mutation: UseMutationResult<AxiosResponse<T, Error>, unknown, any, unknown>;
  identifier: GUID | number | undefined | null;
  customMessage?: React.ReactNode;
  handleSuccess?: (identifier: any) => void;
  handleError?: (identifier: any, err: unknown) => void;
  backToRoot?: boolean;
  okayToDelete?: boolean;
  icon?: boolean;
  title?: string;
  confirmText?: string;
}

function Delete<T>(props: Props<T>) {
  const { t } = useLanguage();
  let navigate = useNavigate();
  const {
    mutation,
    identifier,
    customMessage,
    handleSuccess,
    handleError,
    okayToDelete = true,
    icon,
    backToRoot,
    title = t("Delete"),
    confirmText = t("Yes, Delete"),
    ...buttonProps
  } = props;
  const [confirmOpen, setConfirmOpen] = React.useState(false);

  const handleOpen = () => {
    setConfirmOpen(true);
  };

  const handleOk = () => {
    props.identifier &&
      props.mutation.mutate(props.identifier, {
        onSuccess: () => {
          setConfirmOpen(false);
          props.handleSuccess?.(props.identifier);
          backToRoot && navigate("../");
        },
        onError: (err) => {
          props.handleError?.(props.identifier, err);
        },
      });
  };

  const handleCancel = () => {
    props.mutation.reset();
    setConfirmOpen(false);
  };

  return (
    <>
      {props.icon ? (
        <IconButton
          data-cy="delete-icon-button"
          color="error"
          {...buttonProps}
          onClick={handleOpen}
          disabled={!!props.mutation.isLoading || !!props.disabled || props.mutation.isLoading || !props.identifier}
        >
          {props.mutation.isLoading ? <CircularProgress size={18} color="inherit" /> : <DeleteForever />}
        </IconButton>
      ) : (
        <Button
          data-cy="delete-button"
          color="error"
          variant="contained"
          endIcon={props.mutation.isLoading ? <CircularProgress size={18} color="inherit" /> : <DeleteForever />}
          {...buttonProps}
          onClick={handleOpen}
          disabled={!!props.mutation.isLoading || !!props.disabled || props.mutation.isLoading || !props.identifier}
        >
          {title}
        </Button>
      )}
      <ConfirmDelete
        open={confirmOpen}
        handleClose={handleCancel}
        handleConfirm={handleOk}
        title={title}
        confirmText={confirmText}
        content={
          <>
            {props.customMessage || t("This will be immediately deleted")}
            {props.mutation.isError ? (
              <>
                <Typography component="div" variant="caption" color="error">
                  {t("There was an error")}...
                </Typography>
                <Typography component="div" variant="caption" color="error">
                  {(props.mutation.error as Error).message}
                </Typography>
              </>
            ) : null}
          </>
        }
        cancelText={props.mutation.isError || !props.okayToDelete ? t("Close") : t("No, Cancel")}
        confirmDisabled={props.mutation.isError || props.mutation.isLoading || !props.identifier || !okayToDelete}
        active={props.mutation.isLoading}
      />
      {/* <Dialog maxWidth="xs" open={confirmOpen}>
        <DialogTitle>{okayToDelete ? `${t("Are you sure")}?` : t("Can't delete at this time")}</DialogTitle>
        <DialogContent>
          {props.customMessage || t("This will be immediately deleted")}.
          {props.mutation.isError ? (
            <>
              <Typography component="div" variant="caption" color="error">
                {t("There was an error")}...
              </Typography>
              <Typography component="div" variant="caption" color="error">
                {(props.mutation.error as Error).message}
              </Typography>
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} data-cy="delete-cancel-button">
            {props.mutation.isError || !props.okayToDelete ? t("Close") : t("No, Cancel")}
          </Button>
          <Button
            onClick={handleOk}
            variant="contained"
            color="error"
            disabled={props.mutation.isError || props.mutation.isLoading || !props.identifier || !okayToDelete}
            endIcon={props.mutation.isLoading ? <CircularProgress size={18} color="inherit" /> : <DeleteForever />}
            data-cy="delete-confirm-button"
          >
            {t("Yes, Delete")}
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}

export default Delete;
