import { Box } from "@mui/material";
import Page from "Layout/Page";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Toolbar from "./Toolbar";

type Props = {};

// No Task endpoint
const Tasks = (props: Props) => {
  const navigate = useNavigate();

  return (
    <Page toolbar={<Toolbar />}>
      <Outlet />
    </Page>
  );
};

export default Tasks;
