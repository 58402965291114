import { endOfDay, startOfDay } from "date-fns";
import sub from "date-fns/sub";
import { TLocalStorageSet } from "hooks/useLocalStorage";
import React from "react";

type Props = {};
interface IDateRange {
  dates: Date[];
  selectDates: TLocalStorageSet<Date[]>;
}

const DateRangeCtx = React.createContext<IDateRange | null>(null);
const DateRange = (props: React.PropsWithChildren<Props>) => {
  const [dates, selectDates] = React.useState<Date[]>(() => [
    startOfDay(sub(new Date(), { months: 1 })),
    endOfDay(sub(new Date(), { days: 1 })),
  ]);

  return (
    <DateRangeCtx.Provider value={{ dates, selectDates }}>
      {props.children}
    </DateRangeCtx.Provider>
  );
};

export default DateRange;

export const useDateRange = (duration?: Duration) => {
  const ctx = React.useContext(DateRangeCtx);
  if (!ctx) throw new Error("No Date Range Context");

  React.useLayoutEffect(() => {
    duration && ctx.selectDates([sub(new Date(), duration), new Date()]);
  }, [duration]);

  return ctx;
};
