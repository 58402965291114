import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { ExitToAppOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import useLanguage from "hooks/useLanguage";

interface Props {}

const LoginButton = (props: Props) => {
  const { t } = useLanguage();
  const { instance, inProgress } = useMsal();
  const login = async () => {
    await instance.handleRedirectPromise();
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      await instance.loginRedirect();
    } else {
      console.log("SOME ONE IS ALREADY SIGNED IN");
    }
  };

  return (
    <Button
      onClick={login}
      fullWidth
      size="large"
      variant="contained"
      color="primary"
      endIcon={<ExitToAppOutlined style={{ marginLeft: 32 }} />}
      data-cy="login-button"
    >
      {t("Login")}
    </Button>
  );
};

export default LoginButton;
