export default [
  { code: "cs", title: "Čeština" },
  { code: "dk", title: "Dansk" },
  { code: "de", title: "Deutsch" },
  { code: "en-GB", title: "English" },
  { code: "en-IE", title: "English (Ireland)" },
  { code: "en-US", title: "English (US)" },
  { code: "es", title: "Español" },
  { code: "fr", title: "Français" },
  { code: "it", title: "Italiano" },
  { code: "nl", title: "Nederlandse" },
  { code: "ru", title: "Pусский" },
  { code: "fi", title: "Suomi" },
  { code: "sk", title: "Slovák" },
];
