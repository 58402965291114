import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import LoggedInUser from "./LoggedInUser";

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton onClick={handleClick} data-cy={"toolbar-user"}>
        <AccountCircle />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <Box p={2}>
          <LoggedInUser />
        </Box>
      </Popover>
    </Box>
  );
};

export default UserMenu;
