import useUnits from "hooks/useUnits";
import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  textColor?: string;
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}

const WeightUnit = ({ color = "#000", opacity = 0.7, textColor = "#fff" }: Props) => {
  const { weightUnit } = useUnits();
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          style={{ color, opacity }}
          d="M510.7,481.5L437,161.9c-3.6-19.1-11.2-25-25-25H299.5c6-8.6,9.5-19,9.5-30.2c0-29.3-23.7-53-53-53s-53,23.7-53,53
	c0,11.2,3.5,21.7,9.5,30.2H100c-13.8,0-18.9,8.6-25,25L1.3,481.5c-3.4,14.9,7.2,29.7,22.4,30.5c0.4,0,0.9,0,1.3,0h462
	c0.4,0,0.9,0,1.3,0C503.6,511.2,514.2,496.4,510.7,481.5z M256,76.8c16.5,0,29.9,13.4,29.9,29.9s-13.4,29.9-29.9,29.9
	s-29.9-13.4-29.9-29.9S239.5,76.8,256,76.8z"
        />
        <text
          transform="matrix(1 0 0 1 79.871 414.3448)"
          style={{ fill: textColor, fontFamily: "Arial-Black", fontSize: 240 }}
        >
          {weightUnit}
        </text>
      </svg>
    </SvgIcon>
  );
};

export default WeightUnit;
