import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import React from "react";
import Logo from "./Logo";

type Props = {
  text?: string;
};

const SplashScreen = (props: Props) => {
  return (
    <Box
      sx={{
        bgcolor: "back.dark",
        // background: `radial-gradient(ellipse at 40% 45%, ${theme.palette.primary.light} 0%,  ${theme.palette.back.dark} 40%)`,
      }}
      width={"100vw"}
      height={"100vh"}
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ overflow: "hidden" }}
    >
      <Box sx={{ width: "25%", minWidth: 300, p: 4, zIndex: 1000 }}>
        <Logo text reverse image />
      </Box>

      <CircularProgress sx={{ color: "white" }} />
    </Box>
  );
};

export default SplashScreen;
