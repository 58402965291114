import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}
const Loads = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 730">
        <path
          fill={color}
          stroke="none"
          opacity={opacity}
          d="M689.9,375.6c73.5,0,132.5-59.4,132.5-132.9s-58.9-132.9-132.5-132.9s-132.9,59.4-132.9,132.9S616.3,375.6,689.9,375.6z
	 M689.9,464.2c-103.2,0-310.1,51.8-310.1,155.1V730H1000V619.2C1000,516,793.1,464.2,689.9,464.2z"
        />
        <polyline
          fill="none"
          stroke={color}
          opacity={opacity}
          strokeWidth="60px"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="25,650 100,650 150,600 300,600"
        />
        <polyline
          fill="none"
          stroke={color}
          opacity={opacity}
          strokeWidth="60px"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="900,440 975,60 100,25 220,480 "
        />
      </svg>
    </SvgIcon>
  );
};

export default Loads;
