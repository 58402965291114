import { DarkMode, LightMode } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useColorMode } from "Providers/Theme";
import * as React from "react";
type Props = {
  mode?: "dark" | "light";
};

const ColorMode = (props: Props) => {
  const theme = useTheme();
  const colorMode = useColorMode();
  const _mode = props.mode || theme.palette.mode;
  return (
    <IconButton sx={{ ml: 0 }} onClick={colorMode.toggleColorMode} color="inherit" data-cy={"toolbar-theme"}>
      {_mode === "dark" ? <DarkMode /> : <LightMode />}
    </IconButton>
  );
};

export default ColorMode;
