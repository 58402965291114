import { Button } from "@mui/material";
import React from "react";
import { compareSemVer } from "utils/lib";
import axios from "axios";

type Props = {};

const VersionCheck = (props: React.PropsWithChildren<Props>) => {
  const [error, setError] = React.useState<string>();
  const timer = React.useRef<NodeJS.Timer>();
  const MVersion = () => {
    axios
      .get<string>("/version.txt", {
        responseType: "text",
        headers: {
          Accept: "application/text",
          "Cache-Control": "max-age=0 no-cache no-store must-revalidate max-stale=0 post-check=0 pre-check=0",
          Pragma: "no-cache",
          Vary: "*",
          Expires: "Mon, 26 Jul 1997 05:00:00 GMT",
        },
      })
      .then((res) => res.data)
      .then((res) => {
        const v = compareSemVer(res, process.env.REACT_APP_VERSION as string);
        v === 1 && setError("Latest version is newer than what you're using, try reloading");
        v === -1 && setError("Latest version is older than what you're using, try reloading");
        v === 0 && setError(undefined);
      })
      .catch((err) => {
        console.error("err", err);
        setError("Can't determine correct version");
      });
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      MVersion();
      timer.current = setInterval(MVersion, 1000 * 60 * 5);
    }
    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);

  React.useEffect(() => {
    error && timer.current && clearInterval(timer.current);
  }, [error]);

  return <>{props.children}</>;
  return error ? (
    <>
      {error}
      <div>
        <Button onClick={() => window.location.reload()} variant="contained">
          Reload
        </Button>
      </div>
    </>
  ) : (
    <>{props.children}</>
  );
};

export default VersionCheck;
