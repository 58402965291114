import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonProps,
  CircularProgress,
} from "@mui/material";
import useLanguage from "hooks/useLanguage";
import React from "react";
type VoidFunction = () => void;
export type ConfirmProps = {
  handleClose: VoidFunction;
  handleConfirm: VoidFunction;
  content?: string | React.ReactNode;
  cancelText?: string;
  cancelColor?: ButtonProps["color"];
  cancelVariant?: ButtonProps["variant"];
  confirmColor?: ButtonProps["color"];
  confirmVariant?: ButtonProps["variant"];
  confirmText?: string;
  confirmIcon?: React.ReactNode | null;
  confirmDisabled?: boolean;
  active?: boolean;
} & DialogProps;

const ConfirmDialog = (props: ConfirmProps) => {
  const { t } = useLanguage();
  return (
    <Dialog maxWidth="xs" open={props.open} onClose={props.handleClose}>
      <DialogTitle>{props.title || t("Are you sure")}?</DialogTitle>
      <DialogContent>{props.content || t("Are you sure you want to do this")}?</DialogContent>
      <DialogActions>
        <Button
          data-cy="confirm-cancel-button"
          color={props.cancelColor || "inherit"}
          variant={props.cancelVariant || "text"}
          onClick={props.handleClose}
        >
          {props.cancelText || t("Cancel")}
        </Button>
        <Button
          data-cy="confirm-confirm-button"
          disabled={props.confirmDisabled || false}
          color={props.confirmColor || "primary"}
          variant={props.confirmVariant || "contained"}
          onClick={props.handleConfirm}
          endIcon={
            props.active && props.confirmIcon ? (
              props.active ? (
                <CircularProgress size={18} />
              ) : (
                props.confirmIcon
              )
            ) : (
              props.confirmIcon || null
            )
          }
        >
          {props.confirmText || t("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
