import { useFarm, useSelectedToken } from "@intellync/feedlyncapi";
import {
  AccountCircle,
  AddLocation,
  Apps,
  CameraAlt,
  CreditCard,
  ExpandLess,
  ExpandMore,
  Exposure,
  Grain,
  Groups,
  Home,
  LocalShipping,
  NaturePeople,
  People,
  PlaylistAddCheck,
  Room,
  Settings,
  SettingsEthernet,
  SsidChart,
  Summarize,
  SwapHoriz,
  Timeline,
  TrendingUp,
  ViewComfy,
} from "@mui/icons-material";
import { Box, Collapse, LinearProgress, Toolbar } from "@mui/material";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import {
  BulkTank,
  Contractor,
  Costings,
  FeedManagement,
  Ingredients,
  LoadIn,
  LoadOut,
  Loads,
  MilkJar,
  NorFor,
  Pens,
  Recipes,
  Silo,
  Wageningen,
} from "Components/Icons";
import IfModuleActive from "Features/Modules/Components/IfModuleActive";
import useLanguage from "hooks/useLanguage";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { EModules } from "types/modules";

type Props = {};

const drawerWidth = 260;
const SideMenu = (props: Props) => {
  const theme = useTheme();
  const { t } = useLanguage([]);
  const { selectedToken } = useSelectedToken();
  const QFarm = useFarm(selectedToken);
  /**
   * TEMP
   */
  const isMainUser = true;
  const isAdmin = true;
  /**
   * END TEMP
   */
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          position: "absolute",
          width: drawerWidth,
          boxSizing: "border-box",
          bgcolor:
            theme.palette.mode === "dark"
              ? theme.palette.back.dark
              : theme.palette.back.main,
        },
      }}
    >
      <Toolbar />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="stretch"
        flexGrow={1}
      >
        <Box flexGrow={1} overflow="auto">
          {QFarm.isLoading ? (
            <LinearProgress />
          ) : (
            <List dense={false} data-cy={"side-menu-nav-list"}>
              <NavigationLink icon={<Home />} text={t("Home")} link="/" />

              <IfModuleActive module={EModules.Dynametrix}>
                <SubMenu text={t("NIR")} icon={<CameraAlt />} name="nir">
                  <NavigationLink
                    icon={<Pens />}
                    text={`${t("Pens")}/${t("TMR")}`}
                    link="/nir/nir-pens"
                  />
                  <NavigationLink
                    icon={<Ingredients />}
                    text={t("Ingredients")}
                    link="/nir/nir-ingredients"
                  />
                  <NavigationLink
                    icon={<CameraAlt />}
                    text={t("QuickScans")}
                    link="/nir/nir-quickscans"
                  />
                </SubMenu>
              </IfModuleActive>

              <SubMenu
                text={t("Feed Management")}
                icon={<FeedManagement />}
                name="feed-management"
              >
                <NavigationLink
                  icon={<Ingredients />}
                  text={t("Ingredients")}
                  link="/feed-management/ingredients"
                />
                <NavigationLink
                  icon={<PlaylistAddCheck />}
                  text={t("Feedplans")}
                  link="/feed-management/feedplans"
                />
                <NavigationLink
                  icon={<Recipes />}
                  text={t("Rations")}
                  link="/feed-management/rations"
                />
                <NavigationLink
                  icon={<Pens />}
                  text={t("Pens")}
                  link="/feed-management/pens"
                />

                <NavigationLink
                  icon={<Grain />}
                  text={t("Concentrates")}
                  link="/feed-management/concentrates"
                />

                <NavigationLink
                  icon={<People />}
                  text={t("Operators")}
                  link="/feed-management/operators"
                />
              </SubMenu>

              <SubMenu
                text={`${t("Loads")}/${t("Precision")}`}
                icon={<Loads />}
                name="loads-precision"
              >
                <NavigationLink
                  icon={<Loads />}
                  text={t("Loads")}
                  link="/loads-precision/loads"
                />
                <NavigationLink
                  icon={<LoadIn />}
                  text={t("Loaded Mixes")}
                  link="/loads-precision/loaded-mixes"
                />
                <NavigationLink
                  icon={<LoadOut />}
                  text={t("Fed Mixes")}
                  link="/loads-precision/fed-mixes"
                />
                <NavigationLink
                  icon={<Exposure />}
                  text={t("Mix Precision")}
                  link="/loads-precision/mix-precision"
                />
                {/* <NavigationLink
                  icon={<Summarize />}
                  text={t("Usage By Pens")}
                  link="/loads-precision/usage-by-pen"
                /> */}
                {/* <NavigationLink
                  icon={<Summarize />}
                  text={t("Error Reports")}
                  link="/loads-precision/error-reports"
                /> */}
              </SubMenu>

              <SubMenu
                text={t("Analysis")}
                icon={<TrendingUp />}
                name="analysis"
              >
                <NavigationLink
                  icon={<ViewComfy />}
                  text={t("Intake")}
                  link="/analysis/intakes"
                />
                <NavigationLink
                  icon={<MilkJar />}
                  text={t("Physical Data")}
                  link="/analysis/physical-data"
                />
                <NavigationLink
                  icon={<Timeline />}
                  text={t("Nutrient Data")}
                  link="/analysis/nutrient-data"
                />
                <NavigationLink
                  icon={<SsidChart />}
                  text={t("Feed History")}
                  link="/analysis/feed-history"
                />
                {/* <NavigationLink
                  icon={<Equalizer />}
                  text={t("Custom Charts")}
                  link="/analysis/custom-charts"
                /> */}
                <NavigationLink
                  icon={<Summarize />}
                  text={t("Bulk Tank Reports")}
                  link="/analysis/bulk-tank-reports"
                />
              </SubMenu>

              <IfModuleActive module={EModules["Cow Connect Stock Control"]}>
                <SubMenu
                  text={t("Stock Management")}
                  icon={<Silo />}
                  name="stock"
                >
                  <NavigationLink
                    icon={<NaturePeople />}
                    text={t("Fields")}
                    link="/stock-management/fields"
                  />
                  <NavigationLink
                    icon={<Ingredients />}
                    text={t("Products")}
                    link="/stock-management/products"
                  />
                  <NavigationLink
                    icon={<Silo />}
                    text={`${t("Stocks")}/${t("Silos")}`}
                    link="/stock-management/stocks"
                  />
                  <NavigationLink
                    icon={<SwapHoriz />}
                    text={t("Stock Events")}
                    link="/stock-management/stockevents"
                  />
                  {/* <NavigationLink
                    icon={<Summarize />}
                    text={t("Stock Reports")}
                    link="/stock-management/stockreports"
                  /> */}
                  <NavigationLink
                    icon={<AddLocation />}
                    text={t("Yields")}
                    link="/stock-management/yields"
                  />
                  <NavigationLink
                    icon={<LocalShipping />}
                    text={t("Drivers")}
                    link="/stock-management/drivers"
                  />
                </SubMenu>
              </IfModuleActive>
              {/* <NavigationLink
                icon={<ListAlt />}
                text={t("Tasks")}
                link="/tasks"
              /> */}
              <NavigationLink
                icon={<Costings />}
                text={t("Costings")}
                link="/costings"
              />

              <SubMenu text={t("Settings")} icon={<Settings />} name="settings">
                <NavigationLink
                  icon={<AccountCircle />}
                  text={t("Farm Details")}
                  link="/settings/farms"
                />
                {isMainUser || isAdmin ? (
                  <NavigationLink
                    icon={<CreditCard />}
                    text={t("My Account")}
                    link="/settings/billing"
                  />
                ) : null}
                <NavigationLink
                  icon={<Apps />}
                  text={t("Modules")}
                  link="/settings/modules"
                />
                {QFarm.data?.groups ? (
                  <NavigationLink
                    icon={<Groups />}
                    text={t("Groups")}
                    link="/settings/groups"
                  />
                ) : null}
                <NavigationLink
                  icon={<Room />}
                  text={t("Locations")}
                  link="/settings/locations"
                />
                <NavigationLink
                  icon={<Loads />}
                  text={t("Gateways")}
                  link="/settings/devices"
                />
                <NavigationLink
                  icon={<People />}
                  text={t("Sub-Users")}
                  link="/settings/subusers"
                />
                <NavigationLink
                  icon={<Contractor />}
                  text={t("Contracting")}
                  link="/settings/contracting"
                />
                <NavigationLink
                  icon={<BulkTank />}
                  text={t("Bulk Tanks")}
                  link="/settings/bulktanks"
                />

                <NavigationLink
                  icon={<SettingsEthernet />}
                  text={t("Connections")}
                  link="/settings/connections"
                />
                <IfModuleActive module={EModules.NorFor}>
                  <NavigationLink
                    icon={<NorFor opacity={0.5} />}
                    text={t("NorFor")}
                    link="/settings/norfor"
                  />
                </IfModuleActive>

                <IfModuleActive module={EModules.Wageningen}>
                  <NavigationLink
                    icon={<Wageningen opacity={0.5} />}
                    text={t("Wageningen")}
                    link="/settings/wageningen"
                  />
                </IfModuleActive>
              </SubMenu>
            </List>
          )}
        </Box>
      </Box>
      <Box>
        <Divider />
        {/* <User  /> */}
      </Box>
    </Drawer>
  );
};

export default SideMenu;

export type SubMenuProps = {
  children: any;
  text: string;
  name: string;
  icon: any;
};

export type NavigationLinkProps = {
  icon: any;
  text: string;
  link: string;
};

const NavigationLink = ({ icon, link, text }: NavigationLinkProps) => {
  const [isActive, setIsActive] = React.useState(false);
  const loc = useLocation();
  // const [farm] = useFarm();
  // const appInsights = useAppInsightsContext();
  // const tracker = useTrackMetric(appInsights, link);
  // const trackerEvent = useTrackEvent(appInsights, link, farm.farmId);

  // const track = () => {
  //   tracker();
  //   trackerEvent(farm.farmName);
  // };

  const theme = useTheme();

  let linkStyle: React.CSSProperties = {
    textDecoration: "none",
    color: "inherit",
    transition: "background-color 0.2s",
  };
  let activeStyle: React.CSSProperties = {
    ...linkStyle,
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
  };

  React.useEffect(() => {
    setIsActive(
      loc && loc.pathname && link !== "/"
        ? loc.pathname.startsWith(link)
        : false
    );
  }, [loc, link]);

  return (
    <NavLink
      to={link}
      style={({ isActive }) => (isActive ? activeStyle : linkStyle)}
    >
      <ListItem
        button
        selected={isActive}
        style={{ backgroundColor: "inherit" }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap" }} primary={text} />
      </ListItem>
    </NavLink>
  );
};

const SubMenu = ({ children, text, icon, name }: SubMenuProps) => {
  const [open, setOpen] = React.useState(false);
  const loc = useLocation();

  const handleClick = () => setOpen((state) => !state);

  React.useEffect(() => {
    (children.length ? children : [children]).forEach((c: any) => {
      if (loc.pathname.startsWith(c?.props?.link)) setOpen(true);
    });
  }, [children, loc]);

  return (
    <>
      <ListItem
        button
        onClick={handleClick}
        secondaryAction={open ? <ExpandLess /> : <ExpandMore />}
        data-cy={`sub-menu-${name}-title`}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText style={{ whiteSpace: "nowrap" }} primary={text} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ pl: 4 }}
          data-cy={`sub-menu-${name}-list`}
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};
