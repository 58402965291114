import { Box, Button, Typography, useTheme } from "@mui/material";
import Logo from "Components/Logo";
import React from "react";
import { useNavigate } from "react-router-dom";
type Props = {};

const NotFound = (props: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{ bgcolor: theme.palette.back.dark }}
      width={"100vw"}
      height={"100vh"}
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ overflow: "hidden" }}
    >
      <Box sx={{ width: "25%", minWidth: 300, p: 4, zIndex: 1000 }}>
        <Logo text reverse image />
      </Box>
      <Typography variant="h4" color="white">
        Are you lost?
      </Typography>
      <Box mt={4}>
        <Button onClick={() => navigate("/")} variant="contained">
          Go Back
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
