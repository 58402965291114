import { Delete } from "@mui/icons-material";
import useLanguage from "hooks/useLanguage";
import React from "react";
import ConfirmDialog, { ConfirmProps } from "./ConfirmDialog";

const ConfirmDelete = (props: ConfirmProps) => {
  const { t } = useLanguage();
  return (
    <ConfirmDialog
      data-cy="confirm-delete-button"
      confirmIcon={<Delete />}
      confirmColor="error"
      {...props}
      confirmText={props.confirmText || t("Yes, Delete")}
      title={props.title || t("Delete")}
      content={props.content || t("Are you sure you want to delete this")}
    />
  );
};

export default ConfirmDelete;
