import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}
const Home = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 138.56 118">
        <rect x="36.2" width="66.15" height="83.14" fill={color} opacity={opacity} />
        <polygon
          points="118 44.7 118 97.44 20.56 97.44 20.56 44.7 0 44.7 0 118 138.56 118 138.56 44.7 118 44.7"
          fill={color}
          opacity={opacity}
        />
      </svg>
    </SvgIcon>
  );
};

export default Home;
