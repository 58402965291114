import { Edit as EditIcon } from "@mui/icons-material";
import { Button, ButtonProps, IconButton, IconButtonProps } from "@mui/material";
import useLanguage from "hooks/useLanguage";
import { memo } from "react";

type Props = { icon?: boolean } & ButtonProps; //& IconButtonProps;

const Edit = (props: Props) => {
  return props.icon ? <IconButtonEdit {...props} /> : <ButtonEdit {...props} />;
};

export default memo(Edit);

interface ButtonEditProps extends ButtonProps {
  icon?: boolean;
}

const ButtonEdit = (props: ButtonEditProps) => {
  const { t } = useLanguage();
  const { endIcon, ...rest } = props;

  return (
    <Button variant="contained" color="primary" endIcon={<EditIcon />} data-cy="edit-button" {...rest}>
      {t("Edit")}
    </Button>
  );
};

interface IconButtonEditProps extends IconButtonProps {
  icon?: boolean;
}

const IconButtonEdit = (props: IconButtonEditProps) => {
  const { icon, ...rest } = props;

  return (
    <IconButton data-cy="edit-icon-button" {...rest}>
      <EditIcon sx={{ color: "primary.dark" }} />
    </IconButton>
  );
};
