import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}
const Tap = ({ color = "#000", opacity = 0.7, size = "medium" }: Props) => {
  return (
    <SvgIcon fontSize={size}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128">
        <g id="tap">
          <path
            id="base"
            style={{ color, opacity }}
            d="M122.6,59.5c0,11.1,0,21.9,0,32.9c-0.2,0-0.5,0.1-0.7,0.1c-3.9,0-7.8,0-11.7,0c-0.7,0-1.2-0.2-1.4-0.9
		c-0.2-0.6-0.5-1.2-0.7-1.8c-0.6-1.2-1.4-1.8-2.8-1.8c-4.2,0-8.4,0.1-12.5-0.1c-5.3-0.2-9.9,1.3-14,4.7c-3.4,2.8-7.3,4.6-11.7,5
		c-5.3,0.4-10-1-14-4.5c-0.8-0.7-1.7-1.5-2.5-2.1c-6.3-5.1-14.9-3.5-18.6,3.8c-1.5,3-2.2,6.5-3.2,9.8c-0.1,0.3,0.1,0.7,0.3,0.9
		c1.4,1.7,1.6,3.6,1.2,5.6c-0.5,2.3-1.7,3.2-4.1,3.2c-5.3,0-10.6,0-15.9,0c-4.1,0-6-2.6-5-6.6c0.1-0.2,0.1-0.5,0.2-0.6
		c1.9-2.2,1.6-5.1,2.1-7.7c1.5-7.8,4.2-15,8.9-21.4c6.2-8.4,14.6-13.3,25.1-14.7c0.5-0.1,1.1-0.2,1.6-0.3c3.8-0.8,5.6-2.8,5.8-6.8
		c0.1-2.4,0.1-4.8,0.1-7.2c0-1.1,0.4-1.4,1.5-1.4c9.2,0,18.4,0,27.6,0c2.1,0,2.6,0.6,2.7,2.7c0.2,2.7,0.2,5.4,0.8,8
		c0.9,3.9,3.4,5.6,7.3,5.6c5.3,0,10.6,0,15.9,0c2.4,0,3.3-0.6,3.8-2.9c0.3-1.4,1-1.7,2.3-1.6C114.7,59.5,118.5,59.5,122.6,59.5z"
          />
          <path
            id="handle"
            style={{ color, opacity }}
            d="M64.8,34.5c-1.8,0-3.7,0.1-5.5,0c-1.7-0.1-2-0.6-2-2.3c0.1-2.5,0-5.1,0-7.6c0-1-1.4-2-2.6-1.8
		c-3.1,0.7-6.3,1.4-9.4,2.2c-4.8,1.4-8.9,0-11.7-4.2C31.1,17,31.4,12,34.4,8.4c3-3.5,6.6-4.9,11.2-3.4c2.9,0.9,6,1.5,9,2.1
		c0.5,0.1,1.1-0.1,1.5-0.3c5.7-3.3,11.4-3.3,17.1,0c0.5,0.3,1.1,0.5,1.6,0.3c3-0.7,6.2-1.1,9.1-2.2c6-2.2,12.3,2.8,13.3,8.2
		c1.1,5.7-2.9,11.5-8.6,12.4c-1.1,0.2-2.4,0.1-3.5-0.2c-3.3-0.7-6.6-1.6-9.9-2.4c-2-0.5-3.1,0.4-3.1,2.4c0,2.4-0.1,4.9,0,7.3
		c0.1,1.4-0.5,1.8-1.8,1.8C68.5,34.4,66.6,34.4,64.8,34.5C64.8,34.5,64.8,34.5,64.8,34.5z"
          />
          <path
            id="joiner"
            style={{ color, opacity }}
            d="M64.5,36.2c4.3,0,8.6,0,13,0c4.1,0,5,2.1,4,6.2c-0.2,0.7-1.3,1.4-2.1,1.7c-1,0.3-2.1,0.3-3.1,0.3
		c-8.2,0-16.4,0-24.6,0c-1.5,0-3-0.2-3.7-1.7c-0.8-1.7-0.8-3.5,0.2-5.2c0.6-1.1,1.8-1.3,3-1.3C55.6,36.2,60,36.2,64.5,36.2z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default Tap;
