import { Refresh as RefreshIcon } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { UseQueryResult } from "react-query";
import useLanguage from "hooks/useLanguage";

interface Props<T> {
  query: UseQueryResult<T, unknown>;
  disabled?: boolean;
  onReload?: (data: T) => void;
}

function Refresh<T>(props: Props<T>) {
  const { t } = useLanguage();
  const { query, onReload, ...rest } = props;

  return (
    <Button
      data-cy="refresh-button"
      variant="contained"
      onClick={() => {
        props.query.refetch().then((res) => res.data && props.onReload?.(res.data));
      }}
      color="primary"
      endIcon={props.query.isFetching ? <CircularProgress size={18} /> : <RefreshIcon />}
      {...rest}
      disabled={!!props.disabled || props.query.isFetching}
    >
      {t("Reload")}
    </Button>
  );
}

export default Refresh;
