import { Box, TextField } from "@mui/material";
import { Field } from "formik";
import useLanguage from "hooks/useLanguage";
import React from "react";

type Props = {};

// No Task endpoint
const Task = (props: Props) => {
  const { t } = useLanguage();

  return (
    <Box>
      <Field as={TextField} variant="standard" fullWidth type="text" label={t("Name")} name="name" required />
    </Box>
  );
};

export default Task;
