import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useFarmsSummary } from "@intellync/feedlyncapi";
import useLanguage from "hooks/useLanguage";
import { useSelectedToken } from "@intellync/feedlyncapi";
import * as React from "react";
import { useNavigate } from "react-router-dom";

const filterOptions = createFilterOptions({
  stringify: (option: TOption) => (option.label || "") + (option.farmEmail || ""),
});

type Props = {};

type TOption = { label?: string | null; id: string; farmEmail?: string | null }; //

const AccountSearch = (props: Props) => {
  const navigate = useNavigate();
  const QFarmsSummary = useFarmsSummary();
  const { selectedToken, selectToken } = useSelectedToken();
  const [selected, setSelected] = React.useState<TOption | null>(null);
  const { t } = useLanguage();

  const showSelectedFarm = React.useCallback(() => {
    const selectedFarm = QFarmsSummary.data?.find((t) => t.id === selectedToken);
    selectedFarm?.id && setSelected({ label: selectedFarm.name, id: selectedFarm.id, farmEmail: selectedFarm.email });
  }, [QFarmsSummary.data, selectedToken]);

  React.useEffect(() => {
    if (!selected && selectedToken) {
      showSelectedFarm();
    }
  }, [selectedToken, showSelectedFarm, selected]);

  function handleSelectedChange(event: React.ChangeEvent<{}>, newValue: TOption | null) {
    if (newValue) {
      setSelected(newValue);
      selectToken(newValue.id);
      navigate("/");
    }
  }

  return (
    <>
      <Autocomplete
        sx={{ width: 300, mr: 2 }}
        value={selected}
        options={
          QFarmsSummary.data?.map((f) => ({
            label: f.name || t("Unknown"),
            id: f.id || "",
            farmEmail: f.email || "",
          })) || []
        }
        onChange={handleSelectedChange}
        autoHighlight
        getOptionLabel={(option) => option.label || t("Unknown")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        selectOnFocus
        blurOnSelect
        disableListWrap
        // disableClearable
        filterOptions={filterOptions}
        data-cy={"toolbar-account-search"}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
            }}
            onBlur={(e) => {
              if (!e.currentTarget.value) {
                showSelectedFarm();
              }
            }}
          />
        )}
      />
    </>
  );
};
export default AccountSearch;
