import { Backdrop, Box, CircularProgress, Container, Typography } from "@mui/material";
import React from "react";
import Toolbar from "./Toolbar";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router-dom";
import useDimensions from "hooks/useDimensions";
import ItemList, { ItemListProps, TPossibleKeys } from "Components/ItemList";

type Props<T> = {
  toolbar?: React.ReactNode;
  sidebar?: React.ReactNode;
  itemList?: ItemListProps<T>;
  title?: string | null;
  loading?: boolean;
};

const Page = <T extends TPossibleKeys>(props: React.PropsWithChildren<Props<T>>) => {
  const [divRef, dimensions] = useDimensions();
  let { id = "" } = useParams();

  return (
    <>
      <AnimatePresence>
        {props.toolbar ? (
          <motion.div
            style={{ position: "absolute", zIndex: 1002, width: dimensions.width }}
            transition={{ ease: "easeIn" }}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
          >
            <Toolbar>
              {props.title ? <FadeInTitle title={props.title} /> : <div />}
              {props.toolbar}
            </Toolbar>
          </motion.div>
        ) : null}
      </AnimatePresence>
      <motion.div
        ref={divRef}
        transition={{ ease: "easeIn" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          height: "100%",
          paddingTop: props.toolbar ? 64 : 16,
          display: "flex",
          flexDirection: "row",
          flexGrow: 1,
          overflowY: "auto",
          paddingBottom: 16,
        }}
        data-cy={"page-content"}
      >
        {props.itemList ? <ItemList<T> {...props.itemList} /> : props.sidebar || null}
        <Box flexGrow={1} flex={1} maxWidth="100%" data-cy="page-main">
          {props.children}
        </Box>
      </motion.div>
      <Backdrop
        open={!!id && id !== "new" && (!!props.loading || !!props.itemList?.query.isLoading)}
        style={{ zIndex: 1500 }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
};

export default Page;

type Props2 = { title?: string | null };

const FadeInTitle = (props: Props2) => {
  const { id } = useParams();
  return (
    <div>
      <AnimatePresence>
        {id ? (
          <motion.div
            transition={{ ease: "easeIn" }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
          >
            <Typography variant="button">{props.title}</Typography>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};
