import { AppBar, Box, Container, ThemeProvider, Toolbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import AccountSearch from "Components/AccountSearch";
import ColorMode from "Components/ColorMode";
import LanguageSelect from "Components/LanguageSelect";
import Logo from "Components/Logo";
import PaymentStatusAlert from "Components/PaymentStatusAlert";
import UserMenu from "Components/UserMenu";
import { darkTheme } from "Providers/Theme";
import { Outlet } from "react-router-dom";
import SideMenu from "./SideMenu";

type Props = {};

export const AppLayout = (props: Props) => {
  const theme = useTheme();
  const parentTheme = theme.palette.mode;
  return (
    <Box>
      <CssBaseline />
      <PaymentStatusAlert />
      <Box
        position="relative"
        display="flex"
        minHeight="100vh"
        minWidth="100vw"
        flexGrow={1}
        sx={{ bgcolor: theme.palette.mode === "dark" ? theme.palette.back.main : theme.palette.back.light }}
      >
        <ThemeProvider theme={darkTheme}>
          <AppBar
            position="absolute"
            sx={{
              zIndex: (theme) => theme.zIndex.drawer + 1,
              height: 65,
            }}
          >
            <Toolbar>
              <Logo reverse text strapline flexGrow={1} alignSelf="stretch" m={1} />
              <Box display="flex" alignItems="center">
                {/* {isMainUser || isAdmin ? <FinanceBanner /> : null} */}

                <AccountSearch />
                <ColorMode mode={parentTheme} />
                <LanguageSelect />
                <UserMenu />
              </Box>
            </Toolbar>
          </AppBar>
        </ThemeProvider>
        <SideMenu />
        <Container
          maxWidth="xl"
          style={{ flexGrow: 1, marginTop: 63, width: "calc(100vw - 260px - 48px)", height: "calc(100vh - 63px)" }}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
};
