import { DeleteForever } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { Delete, New, Reset, Save } from "Components/Buttons";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {};

// No Task endpoint
const Toolbar = (props: Props) => {
  let navigate = useNavigate();

  return (
    <Box display="flex">
      <Box mr={1}>
        <Button disabled variant="contained" color="error" endIcon={<DeleteForever />}>
          Delete
        </Button>
        {/* <Delete<TaskDTO> mutation={MDeleteTask} identifier={formik.values.id} /> */}
      </Box>
      <Box mr={1}>{/* <Refresh query={} /> */}</Box>
      <Box mr={1}>
        <New onClick={() => navigate("new", { replace: true })} disabled={false} />
      </Box>
      <Box mr={1}>{/* <Reset disabled={false} onClick={(e) => formik.resetForm()} /> */}</Box>
      <Save onClick={() => {}} disabled={false} />
    </Box>
  );
};

export default Toolbar;
