import { Add } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import useLanguage from "hooks/useLanguage";

interface Props extends ButtonProps {
  saving?: boolean;
}

const New = (props: Props) => {
  const { t } = useLanguage();
  const { saving, ...rest } = props;
  return (
    <Button
      variant="contained"
      disabled={props.disabled || !!props.saving}
      endIcon={<Add />}
      data-cy="new-button"
      {...rest}
    >
      {t("New")}
    </Button>
  );
};

export default New;
