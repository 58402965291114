import { InteractionType, PublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import SplashScreen from "Components/SplashScreen";
import Login from "Pages/Login";
import { PropsWithChildren, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { loginRequest, msalConfig } from "./auth-config";
import { AuthenticatedApiWrapper } from "./AuthenticatedApiWrapper";
import AuthError from "./AuthError";
import AuthLoading from "./AuthLoading";
import { CustomNavigationClient } from "./NavigationClient";

const pca = new PublicClientApplication(msalConfig);
interface Props {}

const AuthProvider = (props: PropsWithChildren<Props>) => {
  const history = useNavigate();
  const navigationClient = new CustomNavigationClient(history);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <Suspense fallback={<SplashScreen />}>
        <AuthenticatedTemplate>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={AuthError}
            loadingComponent={AuthLoading}
          >
            <AuthenticatedApiWrapper>{props.children}</AuthenticatedApiWrapper>
          </MsalAuthenticationTemplate>
        </AuthenticatedTemplate>

        <UnauthenticatedTemplate>
          <Login />
        </UnauthenticatedTemplate>
      </Suspense>
    </MsalProvider>
  );
};

export { AuthProvider };
