import { LocalizationProvider } from "@mui/x-date-pickers";
import { SnackbarProvider } from "notistack";
import DateRange from "Providers/DateRange";
import ReactQuery from "Providers/ReactQuery";
import SelectedToken from "Providers/SelectedToken";
import Farms from "Providers/Farms";
import VersionCheck from "Providers/VersionCheck";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "Routes/Routes";
import "./App.css";
import { ErrorBoundary } from "./Components/ErrorBoundary";
import { AuthProvider } from "./Providers/Auth/AuthProvider";
import { Theme } from "./Providers/Theme";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function App() {
  return (
    <ErrorBoundary>
      <VersionCheck>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <SnackbarProvider maxSnack={5}>
              <SelectedToken>
                <Theme>
                  <AuthProvider>
                    <ReactQuery>
                      <ErrorBoundary>
                        <Farms>
                          <ErrorBoundary>
                            <DateRange>
                              <Routes />
                            </DateRange>
                          </ErrorBoundary>
                        </Farms>
                      </ErrorBoundary>
                    </ReactQuery>
                  </AuthProvider>
                </Theme>
              </SelectedToken>
            </SnackbarProvider>
          </BrowserRouter>
        </LocalizationProvider>
      </VersionCheck>
    </ErrorBoundary>
  );
}

export default App;
