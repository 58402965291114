import { GUID } from "./generics";

export enum EModuleCategories {
  "FormulationSoftwareModules",
  "GrassCalculationModules",
  "HerdManagementModules",
  "ManualFeedModules",
  "MilkProcessorModules",
  "NIRModules",
  "SiloAutomationModules",
  "StockControlModules",
}

export enum EModules {
  // grass
  VemGrassCalculator = "58ebbe12-290a-4b42-8669-13843c8362af",

  // herd management
  xBoviSync = "c995160d-7136-4f70-8e26-139c52362f99",
  "PC Dart" = "1ad8616a-0c2b-4890-b1cc-354e7ebfe78f",
  "Farm Wizard" = "b3f3854b-2c90-4bd9-ab85-371e6ba075d9",
  "Uniform Agri" = "f12239c2-1015-4d40-b73e-577eb9edfb07",
  "Cow Connect Parlour Link" = "c7ca62a0-6a3f-48b6-a106-7f580f0127de",
  "Dairy Comp" = "ca1215de-5964-41b8-981d-870921d292eb",
  "Dairy Comp New Data Loader" = "91256d05-d1e9-463e-84d4-ca2f2fccaff5",
  "AFI Milk" = "d808a84c-bf40-4868-84aa-ccf3b113ea78",
  "Uniform New Data Loader" = "e87afd77-3784-4b62-9f5b-da0366439830",
  HerdPlus = "8a89a443-23d8-4d4d-88cb-e16018240966",

  //NIR
  Dynametrix = "9bb6f1ec-54aa-47e1-bdf7-602c3153c51d",

  // Formulation Software
  Fodjan = "a6b78a6d-8ddc-4ff4-9a32-3578d4530331",
  Wageningen = "ad3b0c4e-58ae-474e-af16-38ee783fb177",
  "Dairy Margin Tracker" = "0ed854fc-ae03-4330-a08f-473d0031cae1",
  Mooml = "85b16e75-b549-4c74-851b-9046b34f4faa",
  NorFor = "3a25551a-bbb5-4d86-960a-c8065c21b578",
  "X Herde Plus" = "bcf86133-3f3e-44ea-ab96-cdd9b18342db",
  Godrej = "6bce0526-5edd-4215-8961-fc584c46b73c",

  // Manual Feed
  Parlour = "17aa836e-b973-4840-b927-01e47614d7b0",
  Feedplan = "c2590b90-2dca-4008-97b0-54ff69e13bfd",

  // silo automation
  "CowConnect Silo Automation" = "57d97f70-fb0b-4e02-8c74-2f2769a42022",

  // stock control
  "Cow Connect Stock Control" = "fa8700d2-8a17-4814-a3c0-1e4b3fe3971e",

  // milk processor
  "Dutch Milk" = "b9f57af8-eadc-45c8-9791-0e5e34d0cfe7",
  "Dairy.com" = "704d97cf-2e1a-4c7d-9fa6-0f61a428fe85",
  "Manual Milk" = "43e3542f-c13d-4301-94d2-21666217ada6",
  Muller = "195a5c3f-65a9-419b-ba5e-4ec9a3b42590",
  Glanbia = "3f62b0f5-425f-4382-996d-8af483cb200b",
  "County Milk" = "3d5c7726-5984-4e4a-8d11-8e3e097bb771",
  Roddas = "7b2dfb92-7cf3-474c-9662-adc9c1456a82",
  Lactalis = "9826264b-f6fd-4ff9-8206-c81d1b0e47b2",
  "Friesland Campinia" = "439eb4b4-7dae-4fe1-b6d6-cea32ddd90a8",
  "Meadow Foods" = "54fa342d-6e2b-4955-935b-d662eb8b3a8d",
  Arla = "ec4d74d6-1e87-457c-ae55-d8b651a5dfa0",
  Pattemores = "872af7da-44e8-4ea6-8165-e9925810459a",
  "No Processor Link" = "aa4a90bf-7f4b-4efc-955e-fa8acd8191ae",
}

export type TGenericModule = {
  id: GUID;
  name?: string | null;
  logo?: string | null;
  langCode?: string | null;
  description?: string | null;
  shortDescription?: string | null;
};
