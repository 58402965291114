import useLocalStorage from "hooks/useLocalStorage";
import React from "react";
import { GUID } from "types/generics";
import { Provider } from "@intellync/feedlyncapi";

type Props = {};

const SelectedToken = (props: React.PropsWithChildren<Props>) => {
  const [selected, setSelected] = useLocalStorage<GUID>("SelectedToken", "");
  return <Provider store={{ get: selected, set: setSelected }}>{props.children}</Provider>;
};

export default SelectedToken;
