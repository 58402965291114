import { useAccount, useMsal } from "@azure/msal-react";
import { Box, Typography } from "@mui/material";
import React from "react";
import Logout from "./Auth/LogoutButton";

type Props = {};

const LoggedInUser = (props: Props) => {
  const { accounts } = useMsal();
  const A = useAccount(accounts[0] || {});
  return (
    <Box display="flex" flexDirection="column" alignItems="center" my={2} data-cy={"toolbar-user-logged-in"}>
      <Typography variant="subtitle2">{A?.name}</Typography>
      <Typography variant="caption" mb={3}>
        {A?.username}
      </Typography>
      <Logout />
    </Box>
  );
};

export default LoggedInUser;
