import { useTranslation, UseTranslationResponse } from "react-i18next";
import { Locale } from "date-fns";
// import * as Locales from "date-fns/locale";

import { da, de, enGB, enUS, nl, fi, cs, ru, sk, fr, it, es } from "date-fns/locale";
const Locales = { da, de, enGB, enUS, nl, fi, cs, ru, sk, fr, it, es };

const useLanguage = (ns = []) => {
  const translations: UseTranslationResponse<"translation", undefined> = useTranslation(ns);

  // This is to specifically name exceptions to the i18n library language codes and the browsers country codes being different.
  // i18n libraries use ISO codes and the browser uses NATO codes
  // E.G. Denmark in i18n is DK but the browser Danish is DA
  const countryCode = () => {
    switch (translations.i18n.language) {
      case "dk":
        return "da";
      default:
        return translations.i18n.language;
    }
  };
  const code = countryCode();
  const n = (num: number) => new Intl.NumberFormat(code, { maximumFractionDigits: num }).format;
  const lang = code.replace(/-/gi, "");
  // @ts-ignore
  const locale = Locales[lang] || Locales["enGB"];

  return {
    ...translations,
    d: new Intl.DateTimeFormat(code).format,
    code,
    n0: n(0),
    n1: n(1),
    n2: n(2),
    n3: n(3),
    locale,
  };
};

export default useLanguage;
