import { useFarm } from "@intellync/feedlyncapi";
import { EModules } from "types/modules";

const useIsModuleActive = () => {
  const QFarm = useFarm();
  const isModuleActive = (module: EModules | string) => {
    const moduleInMilkProcessorModules = QFarm.data?.farmMilkProcessorModules?.find(
      (m) => m.milkProcessorModuleId === module
    );
    const moduleInHerdManagementModules = QFarm.data?.farmHerdManagementModules?.find(
      (m) => m.herdManagementModuleId === module
    );
    const moduleInStockControlModules = QFarm.data?.farmStockControlModules?.find(
      (m) => m.stockControlModuleId === module
    );
    const moduleInSiloAutomationModules = QFarm.data?.farmSiloAutomationModules?.find(
      (m) => m.siloAutomationModuleId === module
    );
    const moduleInFormulationSoftwareModule = QFarm.data?.farmFormulationSoftwareModules?.find(
      (m) => m.formulationSoftwareModuleId === module
    );
    const moduleInGrassCalculationModule = QFarm.data?.farmGrassCalculationModules?.find(
      (m) => m.grassCalculationModuleId === module
    );
    const moduleInManualFeedModules = QFarm.data?.farmManualFeedModules?.find((m) => m.manualFeedModuleId === module);
    const moduleInNIRModules = QFarm.data?.farmNIRModules?.find((m) => m.nirModuleId === module);

    return (
      moduleInMilkProcessorModules ||
      moduleInHerdManagementModules ||
      moduleInStockControlModules ||
      moduleInSiloAutomationModules ||
      moduleInFormulationSoftwareModule ||
      moduleInGrassCalculationModule ||
      moduleInManualFeedModules ||
      moduleInNIRModules
    );
  };
  return isModuleActive;
};

export default useIsModuleActive;
