import { useMsal, useAccount, useIsAuthenticated } from "@azure/msal-react";
import { PropsWithChildren } from "react";
import { loginRequest } from "./auth-config";
import React from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { axios_instance } from "@intellync/feedlyncapi";
import SplashScreen from "Components/SplashScreen";

const baseUrl = (process.env.REACT_APP_API_BASE_URL as string) ?? "";
const apiVersion = (process.env.REACT_APP_API_VERSION as string) ?? "1";
axios_instance.defaults.baseURL = `${baseUrl}/api/v${apiVersion}/`;

function AuthenticatedApiWrapper(props: PropsWithChildren<{}>) {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [interceptorsSet, setInterceptorsSet] = React.useState(false);
  const isAuthenticated = useIsAuthenticated();

  const getBearerToken = React.useCallback(async () => {
    if (account && isAuthenticated) {
      return await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: account,
        })
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            if (account) {
              instance.acquireTokenRedirect({ ...loginRequest, account });
            }
          }
          console.log(error);
        });
    } else {
      // DO SOMETHING
    }
  }, [account, isAuthenticated, instance, inProgress]);

  React.useEffect(() => {
    const createInterceptor = async () => {
      axios_instance.interceptors.request.use(
        async (config) => {
          const _config = { ...config };
          if (_config.headers === undefined) {
            _config.headers = {};
          }
          try {
            const bearer = await getBearerToken();
            bearer && (_config.headers.Authorization = `Bearer ${bearer}`);

            // const _token = localStorage.getItem("__FEEDLYNC__SelectedToken");
            // if (_token) _config.headers.token = JSON.parse(_token);
            // else delete _config.headers.token;

            return _config;
          } catch (err) {
            console.log("TOKEN ERROR");
            Promise.reject(err);
          }
        },
        (error) => Promise.reject(error)
      );
      setInterceptorsSet(true);
    };
    !interceptorsSet && createInterceptor();
  }, [isAuthenticated, getBearerToken, instance]);

  return (
    <>
      {instance && isAuthenticated && interceptorsSet ? (
        props.children
      ) : (
        <SplashScreen />
      )}
    </>
  );
}

export { AuthenticatedApiWrapper };
