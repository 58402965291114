import { useAccount, useMsal } from "@azure/msal-react";
import LogoutIcon from "@mui/icons-material/Logout";
import { Button } from "@mui/material";
import useLanguage from "hooks/useLanguage";

interface Props {}

const Logout = (props: Props) => {
  const { t } = useLanguage();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const logout = async () => {
    if (account?.homeAccountId) {
      const currentAccount = instance.getAccountByHomeId(account?.homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/?page=logout",
      });
    }
  };
  return (
    <Button onClick={logout} variant="contained" endIcon={<LogoutIcon />} data-cy="logout-button">
      {t("Logout")}
    </Button>
  );
};

export default Logout;
