import React, { useState, useEffect } from "react";
import useLanguage from "../hooks/useLanguage";
import languages from "../locales/languages";
import { Box, Button, Menu, MenuItem } from "@mui/material";

interface Props {}
type Language = {
  code: string;
  title: string;
};

const LanguageSelect = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>();
  const [language, setLanguage] = useState<Language | null>();
  const { i18n } = useLanguage();

  useEffect(() => {
    const foundLanguage = languages.find((l) => l.code === i18n.language);
    foundLanguage ? setLanguage(foundLanguage) : setLanguage(languages.find((l) => l.code === "en-GB"));
  }, [i18n.language]);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const selectLanguage = (lng: string) => {
    setAnchorEl(null);
    lng && i18n.changeLanguage(lng, (x) => x && console.error(x));
  };

  const handleClose = (e: React.MouseEvent) => {
    setAnchorEl(null);
  };

  const flagFromCode = (c: string | undefined) => {
    if (!c) return;
    let _code = c.toLowerCase();
    if (_code === "cs") _code = "cz";
    if (_code.includes("-")) _code = _code.slice(_code.indexOf("-") + 1);

    return (
      <img
        loading="lazy"
        width="32"
        src={`https://flagcdn.com/w20/${_code.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${_code.toLowerCase()}.png 2x, https://flagcdn.com/w40/${_code.toLowerCase()}.png 3x`}
        alt={_code}
      />
    );
  };

  return (
    <Box>
      <Button size="small" onClick={handleClick} data-cy={"toolbar-language"}>
        {flagFromCode(language?.code)}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-cy={"toolbar-language-menu"}
      >
        {languages.map((l: any, index: number) => {
          return (
            <MenuItem key={index} onClick={(e) => selectLanguage(l.code)}>
              <span>{flagFromCode(l?.code)}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default LanguageSelect;
