import React from "react";

const useDimensions = (excludePadding = false) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = React.useState<any>({
    width: 0,
    height: 0,
  });
  const handleSetDimensions = React.useCallback(() => {
    if (ref?.current) {
      const style = window.getComputedStyle(ref.current, null);
      const innerSize = { height: style.height, width: style.width };
      const outterSize = ref.current.getBoundingClientRect().toJSON();
      setDimensions(excludePadding ? innerSize : outterSize);
    }
  }, [excludePadding]);

  React.useLayoutEffect(() => {
    handleSetDimensions();
  }, [handleSetDimensions]);

  React.useEffect(() => {
    const listener = () => {
      handleSetDimensions();
    };
    window.addEventListener("resize", listener);
    return () => {
      window.removeEventListener("resize", listener);
    };
  }, [handleSetDimensions]);

  return [ref, dimensions];
};

export default useDimensions;
