import { Box, BoxProps, Card } from "@mui/material";
import React from "react";

interface Props extends BoxProps {}

const OutlinedCard = (props: React.PropsWithChildren<Props>) => {
  return (
    <Box {...props}>
      <Card variant="outlined" style={{ flex: 1 }}>
        <Box p={2} flex={1}>
          {props.children}
        </Box>
      </Card>
    </Box>
  );
};

export default OutlinedCard;
