import { SvgIcon } from "@mui/material";
import React from "react";

interface Props {
  color?: string;
  opacity?: number;
  size?: "small" | "large" | "inherit" | "medium";
}
const Milk = ({ color = "#000", opacity = 0.7 }: Props) => {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M427.8,159.4c6-6,6-15.9,0-21.9l-23.6-23.6c-6-6-15.9-6-21.9,0L364,132c-5.5-7.9-9.1-15.5-9.1-22.7V62.1h24.6
	c8.5,0,15.5-7,15.5-15.5V18c0-8.5-7-15.5-15.5-15.5H256H132.5C124,2.5,117,9.5,117,18v28.6c0,8.5,7,15.5,15.5,15.5h24.6v47.2
	c0,7.2-3.6,14.8-9.1,22.7l-18.1-18.1c-6-6-15.9-6-21.9,0l-23.6,23.6c-6,6-6,15.9,0,21.9l20,20c0.7,0.7,1.5,1.4,2.4,2
	c-6.2,8.9-10.5,17.7-10.6,26.3v285.1c0,8.5,7,15.5,15.5,15.5H256h144.4c8.5,0,15.5-7,15.5-15.5V207.6c-0.1-8.5-4.3-17.4-10.6-26.3
	c0.8-0.6,1.6-1.2,2.4-2L427.8,159.4z"
          color={color}
          opacity={opacity}
        />
        {/* <path
          d="M361.74,176.31l15.46-15.46a12,12,0,0,0,0-17l-11.13-11.12a12,12,0,0,0-17,0l-13.55,13.55a24.42,24.42,0,0,1-3.09-11V98.84h2.45a12,12,0,0,0,12-12V77a12,12,0,0,0-12-12H177.09a12,12,0,0,0-12,12v9.86a12,12,0,0,0,12,12h2.45v36.47a24.42,24.42,0,0,1-3.09,11L162.9,132.76a12,12,0,0,0-17,0L134.8,143.88a12,12,0,0,0,0,17l15.46,15.46a13.06,13.06,0,0,0,1.27,1.09c-10.1,11.41-19.1,22.95-19.2,33.91V431.77a12,12,0,0,0,12,12H367.67a12,12,0,0,0,12-12V211.31c-.1-11-9.1-22.5-19.2-33.91A13.06,13.06,0,0,0,361.74,176.31Z"
        
        /> */}
      </svg>
    </SvgIcon>
  );
};

export default Milk;
