import { PriorityHighRounded } from "@mui/icons-material";
import { Box, Card, Typography } from "@mui/material";
import useLanguage from "hooks/useLanguage";

type Props = { warning: string };

const RouteSelectWarning = (props: Props) => {
  const { t } = useLanguage();
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        backgroundColor: "#0000000a",
        boxShadow: "#0000001a 0px 1px 40px 0px inset",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <PriorityHighRounded sx={{ fontSize: 82 }} color={"warning"} />
        <Typography variant="h5">{t(props.warning)}</Typography>
      </Box>
    </Card>
  );
};

export default RouteSelectWarning;
