import { usePaymentStatus } from "@intellync/feedlyncapi";
import { Alert } from "@mui/material";
import { useIsAccountOwner } from "hooks/useIsAccountOwner";
import React from "react";

type Props = {};

const PaymentStatusAlert = (props: Props) => {
  const isUserAccuntOwner = useIsAccountOwner();
  const [display, setDisplay] = React.useState(true);
  const QPaymentStatus = usePaymentStatus();
  if (!isUserAccuntOwner) return null;
  if (!display) return null;
  if (QPaymentStatus.isLoading) return null;
  if (QPaymentStatus.isError) return null;
  const variants = ["success", "info", "warning", "warning", "error"] as const;
  if (QPaymentStatus.data?.id === 0) return null;
  return (
    <Alert severity={variants[QPaymentStatus.data?.id!]} onClose={() => setDisplay(false)}>
      {QPaymentStatus.data?.message}
    </Alert>
  );
};

export default PaymentStatusAlert;
