import { useFarmsSummary, useSelectedToken } from "@intellync/feedlyncapi";
import ErrorScreen from "Components/ErrorScreen";
import SplashScreen from "Components/SplashScreen";
import { useLogout } from "hooks/useLogout";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

type Props = {};

const Farms = (props: React.PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const requestedFarmId = params.get("id");
  const QFarmsSummary = useFarmsSummary();
  const { selectToken, selectedToken } = useSelectedToken();
  const logout = useLogout();

  React.useEffect(() => {
    if (QFarmsSummary.data) {
      if (
        requestedFarmId &&
        QFarmsSummary.data?.find((farm) => farm.id === requestedFarmId)
      ) {
        // If QS contains an ID and its in my list
        selectToken(requestedFarmId);
        setParams({ id: "" });
      } else if (!selectedToken) {
        // If there is no selected farm
        if (QFarmsSummary.data?.[0]?.id) {
          selectToken(QFarmsSummary.data[0].id);
        } else {
          navigate("/no-farms");
        }
      } else if (
        !QFarmsSummary.data?.find((farm) => farm.id === selectedToken)
      ) {
        // If the selected farm isn't in my list
        if (QFarmsSummary.data?.[0]?.id) {
          selectToken(QFarmsSummary.data[0].id);
        } else {
          navigate("/no-farms");
        }
      }
    }
  }, [QFarmsSummary.data, selectedToken, requestedFarmId]);

  if (QFarmsSummary.isError) {
    logout();
  }

  // if (QFarmsSummary.isLoading) {
  //   return <SplashScreen />;
  // }

  return <>{props.children}</>;
};

export default Farms;
