import { Undo } from "@mui/icons-material";
import { Button, ButtonProps } from "@mui/material";
import useLanguage from "hooks/useLanguage";

interface Props extends ButtonProps {
  saving?: boolean;
}

const Reset = (props: Props) => {
  const { t } = useLanguage();
  const { saving, ...rest } = props;
  return (
    <Button
      data-cy="reset-button"
      variant="contained"
      disabled={props.disabled || !!props.saving}
      endIcon={<Undo />}
      {...rest}
    >
      {t("Reset")}
    </Button>
  );
};

export default Reset;
