import React, { PropsWithChildren, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

interface Props {
  children: ReactNode;
}
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 1, // 1 hour
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
    },
  },
});

// const localStoragePersistor = createWebStoragePersister({ storage: window.localStorage });

// persistQueryClient({
//   queryClient,
//   persister: localStoragePersistor,
// });

const ReactQuery = (props: PropsWithChildren<Props>) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {props.children}
    </QueryClientProvider>
  );
};

export default ReactQuery;
