import React from "react";
import { useAccount, useMsal } from "@azure/msal-react";

export function useLogout() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const logout = React.useCallback(async () => {
    if (account?.homeAccountId) {
      const currentAccount = instance.getAccountByHomeId(account?.homeAccountId);
      await instance.logoutRedirect({
        account: currentAccount,
        postLogoutRedirectUri: "/",
      });
    }
  }, []);

  return logout;
}
