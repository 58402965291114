import { InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { Box, Typography, useTheme } from "@mui/material";
import LoginButton from "Components/Auth/LoginButton";
import Logo from "Components/Logo";
import React from "react";
import { useSearchParams } from "react-router-dom";
type Props = {};

const Login = (props: Props) => {
  const theme = useTheme();
  const { instance, inProgress } = useMsal();
  const [searchParams] = useSearchParams();
  const justLoggedOut = searchParams.get("page") === "logout";

  const login = async () => {
    await instance.handleRedirectPromise();
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      await instance.loginRedirect();
    } else {
      console.log("SOME ONE IS ALREADY SIGNED IN");
    }
  };
  React.useEffect(() => {
    login();
    // if (inProgress === InteractionStatus.None && !justLoggedOut) login();
  }, [inProgress, instance, justLoggedOut]);

  return (
    <Box
      sx={{ bgcolor: theme.palette.back.dark }}
      width={"100vw"}
      height={"100vh"}
      display="flex"
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ overflow: "hidden" }}
    >
      <Box sx={{ width: "25%", minWidth: 300, p: 4, zIndex: 1000 }}>
        <Logo text reverse image />
      </Box>
      <Box mt={4} mb={2}>
        <LoginButton />
      </Box>
      <Typography
        component={"div"}
        variant="caption"
        color="inherit"
        sx={{ textAlign: "center", color: "white" }}
      >
        Version: {process.env.REACT_APP_VERSION}
      </Typography>
    </Box>
  );
};

export default Login;
